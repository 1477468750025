import axios from 'axios'
import { doc, getDoc, getDocs, query, where } from '@firebase/firestore'
import qs from 'qs'
import { ISong, Song4334 } from '../models/song'
import { playlistCollection, songsCollection } from '../firebase/config'
import IPlaylist, { ISongInPlaylist, PlaylistWithSongs } from '../models/playlist'
import {
  chunkArray,
  orderSongsByPlaylist,
  playlistSongsToMapById,
  song4334ToSong
} from '../helpers/utils'

const api4334Url = 'https://4334-songbook-nextjs.vercel.app/api/4334'
// const api4334Url = 'http://localhost:3000/api/4334'
const apiKey4334 = '9ce65858-21ec-4588-bd5c-cee3cca67c61'

export const get4334SongsByText = (text: string | undefined, page: number) =>
  axios
    .get<Song4334[]>(api4334Url, { params: { q: text, page, apiKey: apiKey4334 } })
    .then((_) => (_.data as Song4334[]).map((__) => song4334ToSong(__)))

export const get4334SongById = (id: string) =>
  axios
    .get<Song4334>(`${api4334Url}/${id}`, { params: { apiKey: apiKey4334 } })
    .then((_) => song4334ToSong(_.data as Song4334))

export const get4334SongByIds = (songIds: string[]) => {
  if (songIds.length === 0) {
    return Promise.resolve([])
  }

  return axios
    .get<Song4334[]>(api4334Url, {
      params: { include: songIds, apiKey: apiKey4334 },
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    .then((_) => _.data)
}

export const getOwnSongById = async (id: string) => {
  const songsRef = doc(songsCollection, id)
  const songDoc = await getDoc(songsRef)
  const songDocData = songDoc.data()

  if (!songDocData) {
    return undefined
  }

  const song: ISong = {
    ...songDocData,
    id: { id, source: 'own' }
  }

  return song
}

export const getOwnSongsByIds = async (songIds: string[]) => {
  if (songIds.length === 0) {
    return []
  }

  const chunkedSongIds = chunkArray(songIds, 10)

  const result: ISong[] = []

  for (const songIdsChunk of chunkedSongIds) {
    const q = query(songsCollection, where('__name__', 'in', songIdsChunk))
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach((_) => {
      result.push({ ..._.data(), id: { id: _.id, source: 'own' } })
    })
  }

  return result
}

export const getFirebasePlaylistsOfUser = async (userId: string) => {
  const q = query(
    playlistCollection
    // where('owners', 'array-contains', userId)
    // orderBy('createdAt', 'desc') // index needs to be created in firebase
  )
  const querySnapshot = await getDocs(q)

  const result: IPlaylist[] = []
  querySnapshot.forEach((_) => {
    result.push({ ..._.data(), id: _.id })
  })

  return result
}

export const getFirebasePlaylistById = async (id: string) => {
  const playlistRef = doc(playlistCollection, id)
  const playlistDoc = await getDoc(playlistRef)
  const playlistDocData = playlistDoc.data()

  if (!playlistDocData) {
    return undefined
  }

  const playlist: IPlaylist = {
    ...playlistDocData,
    id
  }

  const songs4334InPlaylist = (playlist?.songs ?? []).filter((_) => _.id.source === '4334')
  const songs4334Ids = songs4334InPlaylist.map((_) => _.id.id)

  const songsOwnInPlaylist = (playlist?.songs ?? []).filter((_) => _.id.source === 'own')
  const ownSongsIds = songsOwnInPlaylist.map((_) => _.id.id)

  const songs4334ChordKeyMap = playlistSongsToMapById(songs4334InPlaylist)
  const songsOwnChordKeyMap = playlistSongsToMapById(songsOwnInPlaylist)

  const songs4334 = await get4334SongByIds(songs4334Ids)
  const songs4334Converted: ISongInPlaylist[] = songs4334.map((_) => ({
    ...song4334ToSong(_),
    transpose: songs4334ChordKeyMap.get(_.id.toString())?.transpose ?? 0,
    capo: songs4334ChordKeyMap.get(_.id.toString())?.capo ?? 0,
    artist: songs4334ChordKeyMap.get(_.id.toString())?.artist ?? null
  }))

  const songsOwn: ISongInPlaylist[] = (await getOwnSongsByIds(ownSongsIds)).map((_) => ({
    ..._,
    transpose: songsOwnChordKeyMap.get(_.id.id)?.transpose ?? 0,
    capo: songsOwnChordKeyMap.get(_.id.id)?.capo ?? 0,
    artist: songsOwnChordKeyMap.get(_.id.id)?.artist ?? null
  }))

  const playlistSongs = orderSongsByPlaylist(
    [...songs4334Converted, ...songsOwn],
    playlist.songs.map((_) => _.id)
  )

  const playlistWithSongs: PlaylistWithSongs = {
    ...playlist,
    songs: playlistSongs
  }

  return playlistWithSongs
}
